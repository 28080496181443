import React, { useEffect, useState } from 'react';

function App() {

  const [totalKv, setTotalKv] = useState([]);
  const [totalDo, setTotalDo] = useState([]);

  const [totalHttpKv, setTotalHttpKv] = useState([]);
  const [totalHttpDo, setTotalHttpDo] = useState([]);
  const [totalHttpAzure, setTotalHttpAzure] = useState([]);

  const [totalHttpAzureParallel, setTotalHttpAzureParallel] = useState([]);
  const [totalHttpKvParallel, setTotalHttpKvParallel] = useState([]);
  const [totalHttpDoParallel, setTotalHttpDoParallel] = useState([]);
  
  const [totalServiceBindingKvParallel, setTotalServiceBindingKvParallel] = useState([]);
  const [totalServiceBindingDoParallel, setTotalServiceBindingDoParallel] = useState([]);

  useEffect(() => {
    const getTotalsKv = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalServiceBinding');
      const respJson = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalKv({ total: respJson.total, timeTaken, isolateId: respJson.isolateId });
    };

    const getTotalsDo = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalDurableObject');
      const respJson = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalDo({ total: respJson.total, timeTaken, isolateId: respJson.isolateId });
    };

    const getTotalsServiceBindingDoParallel = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalServiceBindingDoParallel');
      const requests = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalServiceBindingDoParallel({ requests, timeTaken });
    };

    const getTotalsHttpKv = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalHttpKv');
      const respJson = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalHttpKv({ total: respJson.total, timeTaken, isolateId: respJson.isolateId });
    };

    const getTotalsHttpKvParallel = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalHttpKvParallel');
      const requests = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalHttpKvParallel({ requests, timeTaken });
    };

    const getTotalsServiceBindingKvParallel = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalServiceBindingKvParallel');
      const requests = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalServiceBindingKvParallel({ requests, timeTaken });
    };

    const getTotalsHttpDo = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalHttpDo');
      const respJson = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalHttpDo({ total: respJson.total, timeTaken, isolateId: respJson.isolateId });
    };

    const getTotalsHttpDoParallel = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalHttpDoParallel');
      const requests = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalHttpDoParallel({ requests, timeTaken });
    };

    const getTotalsHttpAzure = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalHttpAzure');
      const respJson = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalHttpAzure({ total: respJson.total, timeTaken });
    };

    const getTotalsHttpAzureParallel = async () => {
      const start = Date.now();
      const resp = await fetch('/api/totalHttpAzureParallel');
      const requests = await resp.json();
      const end = Date.now();

      const timeTaken = end - start;
      setTotalHttpAzureParallel({ requests, timeTaken });
    };

    const fetchTotals = () => {
      getTotalsKv();
      getTotalsDo();

      getTotalsHttpKv();
      getTotalsHttpDo();
      getTotalsHttpAzure();

      getTotalsHttpAzureParallel();
      getTotalsHttpKvParallel();
      getTotalsHttpDoParallel();

      getTotalsServiceBindingKvParallel();
      getTotalsServiceBindingDoParallel();
    }

    fetchTotals();

    if (false) {
      const interval = setInterval(() => {
        fetchTotals();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div style={{padding: '10'}}>
      <h1>Hello LeedsJS</h1>
      <br/>
      <table border={'1px solid gray'} cellSpacing={0} cellPadding={5} border-collapse={'collapse'}>
        <tr>
          <th style={{width: '10%'}}></th>
          <th style={{width: '30%'}}>Azure</th>
          <th style={{width: '30%'}}>CF KV</th>
          <th style={{width: '30%'}}>CF DO</th>
        </tr>
        <tr>
          <td>via HTTP</td>
          <td>{totalHttpAzure.total} | {totalHttpAzure.timeTaken} ms</td>
          <td>{totalHttpKv.total} | {totalHttpKv.timeTaken} ms</td>
          <td>{totalHttpDo.total} | {totalHttpDo.timeTaken} ms</td>
        </tr>
        <tr>
          <td>Time via ServiceBinding</td>
          <td></td>
          <td>{totalKv.total} | {totalKv.timeTaken} ms</td>
          <td>{totalDo.total} | {totalDo.timeTaken} ms</td>
        </tr>
        <tr>
          <td>via HTTP Parallel</td>
          <td>{JSON.stringify(totalHttpAzureParallel.requests)}
          Time Taken: {totalHttpAzureParallel.timeTaken}</td>
          <td>{JSON.stringify(totalHttpKvParallel.requests)}
          Time Taken: {totalHttpKvParallel.timeTaken}</td>
          <td>{JSON.stringify(totalHttpDoParallel.requests)}
          Time Taken: {totalHttpDoParallel.timeTaken}</td>
        </tr>
        <tr>
          <td>via ServiceBinding Parallel</td>
          <td></td>
          <td>{JSON.stringify(totalServiceBindingKvParallel.requests)}
          Time Taken: {totalServiceBindingKvParallel.timeTaken}</td>
          <td>{JSON.stringify(totalServiceBindingDoParallel.requests)}
          Time Taken: {totalServiceBindingDoParallel.timeTaken}</td>
        </tr>
      </table>
    </div>
  );
}

export default App;
